import { Radio, DatePicker, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { RegisterStepProps } from ".";
import { FocusEvent, useState } from "react";
import dayjs from "dayjs";
import { getCleASS, nssNormalize } from "../../helpers/validators";
import { checkIsNssBeneficiare, checkIsValidNss } from "../../api/demande";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const SocialStep = ({ form, current }: RegisterStepProps) => {
  const { t, i18n } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onBlurDateNaissance = (e: FocusEvent<HTMLInputElement, Element>) => {
    let date = e.target.value;
    if (date) {
      let date1 = dayjs(date, "YYYY-MM-DD");
      let date2 = dayjs(date, "DD-MM-YYYY");
      let date3 = dayjs(date, "DD/MM/YYYY");
      let date4 = dayjs(date, "YYYY/MM/DD");
      let res = date1.isValid()
        ? date1
        : date2.isValid()
        ? date2
        : date3.isValid()
        ? date3
        : date4.isValid()
        ? date4
        : null;
      if (res) {
        form.setFieldsValue({
          demandeur: {
            dateNaissance: res,
          },
        });
      }
    }
    form.validateFields([["demandeur", "dateNaissance"]]);
    form.validateFields([["demandeur", "numSecuriteSociale"]]);
  };

  const nssPattern = () => {
    let nss = form.getFieldValue(["demandeur", "numSecuriteSociale"]) || "";
    let dateNaissance =
      form.getFieldValue(["demandeur", "dateNaissance"]) || "";
    if (`${nss}`.length === 12 && dateNaissance) {
      let year = dateNaissance.format("YY");
      let key = getCleASS(nss);
      let nss2 = `${nss}`.slice(2, 10);
      let pattern = `^${year}${nss2}${key}$`;
      return new RegExp(pattern);
    }
    return new RegExp(`^[0-9]{12}$`);
  };

  return (
    <div
      style={
        current != 0
          ? {
              display: "none",
            }
          : {}
      }
    >
      <div style={{ border: "2px solid #ddd", padding: 10, marginBottom: 20 }}>
        <p
          style={{
            width: i18n.language === "ar" ? "90px" : "140px",
            marginTop: "-24px",
            marginLeft: "5px",
            background: "white",
          }}
        >
          {t("inscription.date_naissance")}
        </p>
        <Form.Item
          name={["demandeur", "presumeDemandeur"]}
          label={t("inscription.presume")}
          initialValue={false}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={false}>{t("inscription.non")}</Radio>
            <Radio value={true}>{t("inscription.oui")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name={["demandeur", "dateNaissance"]}
          label={t("inscription.date_naissance")}
          rules={[
            { required: true },
            {
              validator(_, value) {
                const presume = form.getFieldValue([
                  "demandeur",
                  "presumeDemandeur",
                ]);

                if (!value) {
                  return Promise.resolve();
                }
                if (presume) {
                  if (value.month() !== 0 || value.date() !== 1) {
                    return Promise.reject(
                      new Error(t("messages.presume_invalide").toString())
                    );
                  }
                }
                if (value.isAfter(dayjs().subtract(16, "year"))) {
                  return Promise.reject(
                    new Error(t("messages.date_cnl_invalide").toString())
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            onBlur={onBlurDateNaissance}
            style={{ width: "100%" }}
            placeholder=""
            defaultPickerValue={dayjs().subtract(16, "year")}
            disabledDate={(date) => date.isAfter(dayjs().subtract(16, "year"))}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={["demandeur", "numSecuriteSociale"]}
        label={t("inscription.nss")}
        normalize={nssNormalize}
        dependencies={[["demandeur", "dateNaissance"]]}
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              let test = nssPattern().test(value);
              if (!test) {
                return Promise.reject(
                  new Error(t("messages.nss_invalide").toString())
                );
              }
               return executeRecaptcha &&
              executeRecaptcha().then((token) =>
                checkIsValidNss(value, token).then((success) => {
                  if(!success) {
                    return Promise.reject(
                      new Error(t("messages.nss_exist").toString())
                    );
                  }else{
                    return executeRecaptcha &&
                    executeRecaptcha().then((token) =>
                      checkIsNssBeneficiare(value, token).then((success) => {
                        if(success) {
                          return Promise.reject(
                            new Error(t("messages.nss_beneficiare").toString())
                          );
                        }  
                        return Promise.resolve();
                      }))
                  }
                })
              )
            },
          },
          
        ]}
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </div>
  );
};

export default SocialStep;
