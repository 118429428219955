import { DatePicker, Form, Input, Modal, Select, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { RegisterStepProps } from ".";
import { FocusEvent, useEffect, useState } from "react";
import dayjs from "dayjs";
import { ICommune, ITypeDemande } from "../../types/types";
import { getTypeDemandes } from "../../api/demande";
import { oui_non } from "../../data/data";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { COLOR_PRIMARY2 } from "../../config/constants";
import QuestionCircleTwoTone from "./../../assets/question-circle.svg";
import TextArea from "antd/es/input/TextArea";
import InfoCircleOutlined from './../../assets/infocircle.svg';
import {
  arabicAddressNormalize,
  latinAddressNormalize,
  ncnlNormalize,
} from "../../helpers/validators";
import { getCommunes } from "../../api/wilayas";
const DemandeStep = ({
  form,
  current,
  wilayas,
  conjointExist,
}: RegisterStepProps) => {
  const { t, i18n } = useTranslation();
  const [typesDemande, setTypesDemande] = useState<ITypeDemande[]>([]);
  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [loadingCommunes] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const wilaysFiltered = wilayas?.filter((x) => x.id !== 99);

  const onWilayaChange = (value: number) => {
    form.setFieldsValue({
        communeNaissanceId: undefined,
    });
    return getCommunes(value).then((res) => setCommunes(res));
  };
  useEffect(() => {
    if (!executeRecaptcha) {
      // Modal.error({
      //   title: t("error"),
      //   content: (
      //     <ul>
      //       <li>{t("captcha_error")}</li>
      //     </ul>
      //   ),
      // });
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha();
      const w = await getTypeDemandes(token);
      setTypesDemande(w);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const onBlurDateCnl = (e: FocusEvent<HTMLInputElement, Element>) => {
    let date = e.target.value;
    if (date) {
      let date1 = dayjs(date, "YYYY-MM-DD");
      let date2 = dayjs(date, "DD-MM-YYYY");
      let date3 = dayjs(date, "DD/MM/YYYY");
      let date4 = dayjs(date, "YYYY/MM/DD");
      let res = date1.isValid()
        ? date1
        : date2.isValid()
        ? date2
        : date3.isValid()
        ? date3
        : date4.isValid()
        ? date4
        : null;
      if (res) {
        form.setFieldsValue({ dateDecisionCnl: res });
      }
    }
    form.validateFields(["dateDecisionCnl"]);
  };

  const onBlurDateDelivance = (e: FocusEvent<HTMLInputElement, Element>) => {
    let date = e.target.value;
    if (date) {
      let date1 = dayjs(date, "YYYY-MM-DD");
      let date2 = dayjs(date, "DD-MM-YYYY");
      let date3 = dayjs(date, "DD/MM/YYYY");
      let date4 = dayjs(date, "YYYY/MM/DD");
      let res = date1.isValid()
        ? date1
        : date2.isValid()
        ? date2
        : date3.isValid()
        ? date3
        : date4.isValid()
        ? date4
        : null;
      if (res) {
        form.setFieldsValue({
          dateDelivancePermisConstruction: res,
        });
      }
    }
    form.validateFields(["dateDelivancePermisConstruction"]);
  };
  // conjointExist and current is 3 or !conjointExist and current is 2

  const show =
    (conjointExist && current == 3) || (!conjointExist && current == 2);

    const handleHelp = () => {
      setShowModal(true);
    };
    const styles = {
      helpImg: {
        height: "500px",
        backgroundImage: "url(./help.jpg)",
        backgroundSize: "95%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    };
  return (
    <div
      style={
        !show
          ? {
              display: "none",
            }
          : {}
      }
    >
       
       <Form.Item
        name="typeDemandeId"
        label={t("inscription.demande_aide_pour")}
        rules={[{ required: true }]}
      >
        <Select>
          {typesDemande.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {i18n.language == "ar" ? item.typeAr : item.typeLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
     
     
      <Form.Item
        name="wilayaId"
        label={t("inscription.wilaya_projet")}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <Select onChange={onWilayaChange}>
          {wilaysFiltered?.map((wilaya) => (
            <Select.Option key={wilaya.id} value={wilaya.id}>
              {wilaya.id}-{" "}
              {i18n.language == "ar" ? wilaya.nomWilayaAr : wilaya.nomWilayaLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> 
      <Form.Item
        name="communeId"
        label={t("inscription.commune_projet")}
        dependencies={["wilayaId"]}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <Select loading={loadingCommunes}>
          {communes.map((commune) => (
            <Select.Option key={commune.id} value={commune.id}>
              {i18n.language == "ar"
                ? commune.nomCommuneAr
                : commune.nomCommuneLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
       <Form.Item
        name="dateDecisionCnl"
        label={t("inscription.date_decision_cnl")}
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (value.isBefore(dayjs(typesDemande[0].dateCNLMin))) {
                return Promise.reject(
                  new Error(
                    t(`messages.date_cnl_invalide`).toString() +
                      `${dayjs(typesDemande[0].dateCNLMin).format(
                        "DD/MM/YYYY"
                      )}`
                  )
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          defaultPickerValue={dayjs(typesDemande[0]?.dateCNLMin)}
          style={{ width: "100%" }}
          placeholder=""
          onBlur={onBlurDateCnl}
          disabledDate={(date) => {
            return date.isBefore(dayjs(typesDemande[0]?.dateCNLMin));
          }}
        />
      </Form.Item>
      <Form.Item
        name="numDecisionCnl"
        label={t("inscription.numero_decision_cnl")}
        normalize={ncnlNormalize}
        dependencies={["wilayaId","communeId"]}
        rules={[
          {required:true},
          {
            validator(_, value) {       
              if (!value) {
                return Promise.resolve();
              }
              if (!/^\d{17}$/.test(value)) {
                return Promise.reject(new Error(t("messages.ncnl_17chiffre").toString()));
              }   
              const extractedWilayaId = parseInt(value.substring(2, 4), 10);
              const extractedCommuneId = parseInt(value.substring(4, 6), 10);
              if (extractedWilayaId !== form.getFieldValue("wilayaId")) {
                
                return Promise.reject(new Error(t("messages.ncnl_invalide").toString()));
              }
          
              if (extractedCommuneId !== form.getFieldValue("communeId")) {
                return Promise.reject(new Error(t("messages.ncnl_invalide").toString()));
              }
          
              return Promise.resolve();
            },
          },
      ]}
      >
        <Input  addonAfter={
        <img src={InfoCircleOutlined} onClick={handleHelp} width={30} />
        } />
      </Form.Item>
      <Form.Item
        name={"adresseConstructionFr"}
        label={t("inscription.adress_construction_lt")}
        labelCol={{ lg: i18n.language == "ar" ? 9 : 11 }}
        normalize={latinAddressNormalize}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name={"adresseConstructionAr"}
        labelCol={{ lg: i18n.language == "ar" ? 9 : 11 }}
        label={t("inscription.adress_construction_ar")}
        normalize={arabicAddressNormalize}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name="deuxiemeTrancheVersee"
        label={t("inscription.deuxieme_tranche_versee")}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <Select className="select-travaux-achevés"
          suffixIcon={
            <Tooltip
              title={t("inscription.tooltip_deuxieme_tranche_versee")}
              color={COLOR_PRIMARY2}
            >
              <img
                width={25}
                height={25}
                src={QuestionCircleTwoTone}
                alt="fnpos-question"
              />
            </Tooltip>
          }>
          {oui_non.map((state) => (
            <Select.Option key={state.value} value={state.value}>
              {t(state.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        labelCol={{ lg: i18n.language === "ar" ? 9 : 11 }}
        name="projetFinalise"
        label={t("inscription.projet_finalise")}
        validateTrigger="onBlur"
        rules={[{ required: true }]}
      >
        <Select
          className="select-travaux-achevés"
          suffixIcon={
            <Tooltip
              title={t("inscription.tooltip_projet")}
              color={COLOR_PRIMARY2}
            >
              <img
                width={25}
                height={25}
                src={QuestionCircleTwoTone}
                alt="fnpos-question"
              />
            </Tooltip>
          }
        >
          {oui_non.map((state) => (
            <Select.Option key={state.value} value={state.value}>
              {t(state.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        labelCol={{ lg: i18n.language === "ar" ? 9 : 14 }}
        name="dateDelivancePermisConstruction"
        label={t("inscription.date_delivance_permis_construction")}
        validateTrigger="onBlur"
        rules={[
          { required: true },
          {
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              if (value.isAfter(dayjs())) {
                return Promise.reject(
                  new Error(t("messages.date_invalide").toString())
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          style={{ width: "100%" }}
          placeholder=""
          onBlur={onBlurDateDelivance}
          disabledDate={(date) => date.isAfter(dayjs())}
        />
      </Form.Item>
      <Modal
        footer={null}
        centered
        width={800}
        open={showModal}
        onCancel={() => setShowModal(false)}
      >
        <div style={styles.helpImg}></div>
      </Modal>
    </div>
  );
};

export default DemandeStep;
