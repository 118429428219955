import { Button, Tabs, TabsProps, Typography } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { COLOR_PRIMARY2 } from "../../config/constants";
import {
  sexe,
  situation_familiale,
  situation_professionnelle,
  situation_professionnelle_conjoint,
} from "../../data/data";
import { IProfileData } from "../../types/profile";
import { ReactNode } from "react";
import { downloadJustification } from "../../api/documents";

interface DataEntry {
  label: string;
  value?: string | ReactNode;
}

const DataGrid = ({ data }: { data: DataEntry[] }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 3fr",
        alignItems: "center",
        columnGap: 5,
      }}
    >
      {data
        .filter((d) => d.value)
        .map((item, i) => (
          <>
            <Typography.Text
              style={{
                padding: 10,
                backgroundColor: i % 2 != 0 ? "#EEEEEE" : "    ",
                fontWeight: 800,
              }}
            >
              {item.label}
            </Typography.Text>
            <Typography.Text
              style={{
                padding: 10,
                backgroundColor: i % 2 != 0 ? "#EEEEEE" : "    ",
              }}
            >
              {item.value || "nexiste pas dans lAPI"}
            </Typography.Text>
          </>
        ))}
    </div>
  );
};

const TabsForm = ({ profile }: { profile: IProfileData }) => {
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState<string>("1");
  const datas = (key: string): DataEntry[] => {
    switch (key) {
      case "2":
        return [
          {
            label: t("inscription.nom_latin"),
            value: profile.conjoint?.nomLt,
          },
          {
            label: t("inscription.nom_arabe"),
            value: profile.conjoint?.nomAr,
          },
          {
            label: t("inscription.prenom_latin"),
            value: profile.conjoint?.prenomLt,
          },
          {
            label: t("inscription.prenom_arabe"),
            value: profile.conjoint?.prenomAr,
          },
          {
            label: t("inscription.presume"),
            value: profile?.conjoint?.presumeConjoint && t("inscription.oui"),
          },
          {
            label: t("inscription.date_naissance"),
            value: profile.conjoint?.dateNaissance?dayjs(profile.conjoint?.dateNaissance).format("DD/MM/YYYY"):null,
          },
          {
            label: t("inscription.wilaya_naissance"),
          },
          {
            label: t("inscription.commune_naissance"),
            value:
              i18n.language === "ar"
                ? profile.conjoint?.communeNaissance?.nomCommuneAr
                : profile.conjoint?.communeNaissance?.nomCommuneLt,
          },
          {
            label: t("inscription.n_acte_naissance"),
            value: profile.conjoint?.numActeNaissance,
          },
          {
            label: t("inscription.nss"),
            value: profile.conjoint?.numSecuriteSociale,
          },
          {
            label: t("inscription.nin"),
            value: profile.conjoint?.numIdentificationNational,
          },
          {
            label: t("inscription.prenom_pere_lt"),
            value: profile.conjoint?.prenomPereLt,
          },
          {
            label: t("inscription.prenom_pere_ar"),
            value: profile.conjoint?.prenomPereAr,
          },
          {
            label: t("inscription.nom_mere_lt"),
            value: profile.conjoint?.nomMereLt,
          },
          {
            label: t("inscription.nom_mere_ar"),
            value: profile.conjoint?.nomMereAr,
          },
          {
            label: t("inscription.prenom_mere_lt"),
            value: profile.conjoint?.prenomMereLt,
          },
          {
            label: t("inscription.prenom_mere_ar"),
            value: profile.conjoint?.prenomMereAr,
          },
          {
            label: t("inscription.situation_professionnelle"),
            value: t(
              situation_professionnelle_conjoint.find(
                (s) => s.value === profile.conjoint?.situationProfessionnelle
              )?.label || ""
            ).toString(),
          },
          {
            label: t("inscription.revenu_mensuel"),
            value: profile.conjoint?.revenuMensuel
              ? `${profile.conjoint?.revenuMensuel} ${t("inscription.dinars")}`
              : null,
          },
          {
            label: t("inscription.nss"),
            value: profile.conjoint?.numSecuriteSociale,
          },
        ];
      case "3":
        return [
          {
            label: t("inscription.demande_aide_pour"),
            value:
              i18n.language === "ar"
                ? profile.currentDemande?.typeDemande?.typeAr
                : profile.currentDemande?.typeDemande?.typeLt,
          },
         
          {
            label: t("inscription.wilaya_projet"),
            value:
              i18n.language === "ar"
                ? profile.currentDemande?.wilaya?.nomWilayaAr
                : profile.currentDemande?.wilaya?.nomWilayaLt,
          },
          {
            label: t("inscription.commune_projet"),
            value:
              i18n.language === "ar"
                ? profile.currentDemande?.commune?.nomCommuneAr
                : profile.currentDemande?.commune?.nomCommuneLt,
          },
          {
            label: t("inscription.date_decision_cnl"),
            value: dayjs(profile.currentDemande?.dateDecisionCnl).format(
              "DD/MM/YYYY"
            ),
          },
          {
            label: t("inscription.numero_decision_cnl"),
            value:profile.currentDemande?.numDecisionCnl

          },

          {
            label: t("inscription.adress_construction_lt"),
            value: profile.currentDemande?.adresseConstructionFr,
          },
          {
            label: t("inscription.adress_construction_ar"),
            value: profile.currentDemande?.adresseConstructionAr,
          },
          {
            label: t("inscription.deuxieme_tranche_versee"),
            value: profile.currentDemande?.deuxiemeTrancheVersee
              ? t("inscription.oui").toString()
              : t("inscription.non").toString(),
          },
          {
            label: t("inscription.projet_finalise"),
            value: profile.currentDemande?.projetFinalise
              ? t("inscription.oui").toString()
              : t("inscription.non").toString(),
          },
          {
            label: t("inscription.date_delivance_permis_construction"),
            value: dayjs(
              profile.currentDemande?.dateDelivancePermisConstruction
            ).format("DD/MM/YYYY"),
          },
        ];
      case "4":
        return [
          {
            label: t("justification"),
            value: profile.recours?.justification,
          },
          {
            label: t("fichier_justification"),
            value: (
              <Button onClick={downloadJustification}>
                {t("telecharger_fichier")}
              </Button>
            ),
          },
        ];
      default:
        return [
          {
            label: t("inscription.sexe"),
            value: t(
              sexe.find((s) => s.value === profile.genre)?.label || ""
            ).toString(),
          },
          {
            label: t("inscription.situation_familiale"),
            value: t(
              situation_familiale.find(
                (s) => s.value === profile.situationFamiliale
              )?.label || ""
            ).toString(),
          },
          // nbrEnfants
          {
            label: t("inscription.nbr_enfants"),
            value: profile.nbrEnfants?.toString(),
          },
          {
            label: t("inscription.nbr_enfants_30ans"),
            value: profile.nombreEnfantsMoins30Ans?.toString(),
          },
          {
            label: t("inscription.situation_professionnelle"),
            value: t(
              situation_professionnelle.find(
                (s) => s.value === profile.situationProfessionnelle
              )?.label || ""
            ).toString(),
          },
          {
            label: t("inscription.revenu_mensuel"),
            value: `${profile.revenuMensuel} ${t("inscription.dinars")}`,
          },
          {
            label: t("inscription.experience_professionnelle"),
            value:
              profile.situationProfessionnelle === 1
                ? `${profile.anneeExperienceProfessionnelle} ${t(
                    "inscription.annees"
                  )} / ${profile.moisExperienceProfessionnelle} ${t(
                    "inscription.mois"
                  )}`
                : undefined,
          },

          {
            label: t("inscription.nom_arabe"),
            value: profile.nomAr,
          },
          {
            label: t("inscription.nom_latin"),
            value: profile.nomLt,
          },

          {
            label: t("inscription.prenom_arabe"),
            value: profile.prenomAr,
          },
          {
            label: t("inscription.prenom_latin"),
            value: profile.prenomLt,
          },
          {
            label: t("inscription.presume"),
            value: profile?.presumeDemandeur && t("inscription.oui"),
          },
          {
            label: t("inscription.date_naissance"),
            value: dayjs(profile.dateNaissance).format("DD/MM/YYYY"),
          },
          {
            label: t("inscription.wilaya_naissance"),
          },
          {
            label: t("inscription.commune_naissance"),
            value:
              i18n.language === "ar"
                ? profile.communeNaissance?.nomCommuneAr
                : profile.communeNaissance?.nomCommuneLt,
          },
          {
            label: t("inscription.n_acte_naissance"),
            value: profile.numActeNaissance,
          },
          {
            label: t("inscription.nss"),
            value: profile.numSecuriteSociale,
          },
          {
            label: t("inscription.nin"),
            value: profile.numIdentificationNational,
          },

          {
            label: t("inscription.prenom_pere_ar"),
            value: profile.prenomPereAr,
          },
          {
            label: t("inscription.prenom_pere_lt"),
            value: profile.prenomPereLt,
          },

          {
            label: t("inscription.nom_mere_ar"),
            value: profile.nomMereAr,
          },
          {
            label: t("inscription.nom_mere_lt"),
            value: profile.nomMereLt,
          },

          {
            label: t("inscription.prenom_mere_ar"),
            value: profile.prenomMereAr,
          },
          {
            label: t("inscription.prenom_mere_lt"),
            value: profile.prenomMereLt,
          },
          {
            label: t("inscription.n_tel"),
            value: profile.numTel,
          },
          {
            label: t("inscription.courriel"),
            value: profile.email,
          },
        ];
    }
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Typography.Title
          level={5}
          style={{ color: COLOR_PRIMARY2, margin: 0, textAlign: "center" }}
        >
          {t("etapes.informations_personnelles")}
        </Typography.Title>
      ),
      children: <DataGrid data={datas("1")} />,
    },
    {
      key: "2",
      label: (
        <Typography.Title
          level={5}
          style={{ color: COLOR_PRIMARY2, margin: 0, textAlign: "center" }}
        >
          {t("etapes.informations_conjoint")}
        </Typography.Title>
      ),
      children: <DataGrid data={datas("2")} />,
    },
    {
      key: "3",
      label: (
        <Typography.Title
          level={5}
          style={{ color: COLOR_PRIMARY2, margin: 0, textAlign: "center" }}
        >
          {t("etapes.demande")}
        </Typography.Title>
      ),
      children: <DataGrid data={datas("3")} />,
    },
    {
      key: "4",
      // recours
      label: (
        <Typography.Title
          level={5}
          style={{ color: COLOR_PRIMARY2, margin: 0, textAlign: "center" }}
        >
          {t("etapes.recours")}
        </Typography.Title>
      ),
      children: <DataGrid data={datas("4")} />,
    },
  ];
  const filteredItems = items
    .filter(
      (item) => item.key != "2" || (profile.conjointExiste && item.key === "2")
    )
    .filter(
      (item) =>
        item.key != "4" ||
        (profile.currentDemande?.recoursExist && item.key === "4")
    );
  return (
    <Tabs
      activeKey={activeKey}
      onChange={(k) => setActiveKey(k)}
      tabBarStyle={{
        margin: 0,
      }}
      items={filteredItems}
    />
  );
};

export default TabsForm;
