export const arabicAddressNormalize = (value: any) => {
  if (!value) return "";
  // allow arabic letters and spaces
  //
  return value.replace(/[^ \u0621-\u064A0-9]/g, "");
};

export const latinAddressNormalize = (value: any) => {
  if (!value) return "";
  // return value.replace(/[^a-z0-9 ]/gi, "");
  return value.replace(/[^a-zA-Z0-9àÀâÂçÇéÉèÈêÊëËîÎïÏôÔùÙûÛüÜ°, ]/g, "");
};
export const arabicInputNormalize = (value: any) => {
  if (!value) return "";
  // allow arabic letters and spaces
  return value.replace(/[^ \u0621-\u064A]/g, "");
};

export const latinInputNormalize = (value: any) => {
  if (!value) return "";
  // return value.replace(/[^a-zA-Z ]/g, "");
  return value.replace(/[^a-zA-ZÀ-ÿ ]/g, "");
};

export const nActNormalize = (value: any) => {
  if (!value) return "";
  return value
    .replace(/[^0-9]/g, "")
    .trim()
    .slice(0, 5);
};

export const ninNormalize = (value: any) => {
  if (!value) return "";
  return value
    .replace(/[^0-9]/g, "")
    .trim()
    .slice(0, 18);
};
export const ncnlNormalize = (value: any) => {
  if (!value) return "";
  return value
    .replace(/[^0-9]/g, "")
    .trim()
    .slice(0, 17);
};

export const nssNormalize = (value: any) => {
  if (!value) return "";
  return value
    .replace(/[^0-9]/g, "")
    .trim()
    .slice(0, 12);
};

export const phoneNormalize = (value: any) => {
  if (!value) return "";
  return value
    .replace(/[^0-9]/g, "")
    .trim()
    .slice(0, 10);
};

// export const nssNormalize = (value:any)=>{
//     console.log(value)
//     if (!value) return "";
//     let newValue = `${value}`.replace(/[^0-9]/g, '').trim();
//     if (newValue.length > 2) {
//         newValue = newValue.slice(0, 2) + " " + newValue.slice(2);
//     }
//     if (newValue.length > 7) {
//         newValue = newValue.slice(0, 7) + " " + newValue.slice(7);
//     }
//     if (newValue.length > 12) {
//         newValue = newValue.slice(0, 12) + " " + newValue.slice(12);
//     }
//     return newValue.slice(0, 15);
// }

// export const phoneNormalize = (value: any) => {
//     // max 10 digits
//     // space after every 2 digits
//     if (!value) return "";
//     let newValue = `${value}`.replace(/[^0-9]/g, '').trim();
//     newValue = newValue.slice(0, 10);
//     if (newValue.length > 2) {
//         newValue = newValue.slice(0, 2) + " " + newValue.slice(2);
//     }
//     if (newValue.length > 5) {
//         newValue = newValue.slice(0, 5) + " " + newValue.slice(5);
//     }
//     if (newValue.length > 8) {
//         newValue = newValue.slice(0, 8) + " " + newValue.slice(8);
//     }
//     if (newValue.length > 11) {
//         newValue = newValue.slice(0, 11) + " " + newValue.slice(11);
//     }
//     return newValue;
// }
export const getCleASS = (numeroass: string): string => {
  let cle = "";
  try {
    let somme1 =
      (parseInt(numeroass.substring(0, 1)) +
        parseInt(numeroass.substring(2, 3)) +
        parseInt(numeroass.substring(4, 5)) +
        parseInt(numeroass.substring(6, 7)) +
        parseInt(numeroass.substring(8, 9))) *
      2;

    let somme2 =
      parseInt(numeroass.substring(1, 2)) +
      parseInt(numeroass.substring(3, 4)) +
      parseInt(numeroass.substring(5, 6)) +
      parseInt(numeroass.substring(7, 8)) +
      parseInt(numeroass.substring(9, 10));

    cle = (99 - (somme1 + somme2)).toString();
    cle = cle.padStart(2, "0");
  } catch (e) {
    return cle;
  }
  return cle;
};
