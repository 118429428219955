import { AppTranslationValues } from "./base";

const values: AppTranslationValues = {
  profile: {
    num_inscription: "رقم التسجيل",
    date_inscription: "تاريخ التسجيل",
    statut_demande: "وضعية الطلب",
    statut_recours: "وضعية الطعن",
    date_recours: "تاريخ الطعن",
    date_rdv: "تاريخ الموعد",
    plage: "على الساعة",
  },
  a_propos_du_service_menu: "حول الخدمة",
  navbar: {
    ministere_du_travail: "وزارة العمل و التشغيل",
    emploi: "و الضمان الاجتماعي",
    fnpos1: " الصندوق الوطني لمعادلة",
    fnpos2: " الخدمات الاجتماعية",
    bienvenue: "مرحبا بك",
  },
  formulaire_reset_password: "إعادة تعيين كلمة المرور",
  full_page_header: {
    title:
      "المنصة الرقمية لتسجيل طلبات العمال الـأجراء والمتقاعدين للاستفادة من الاعانة المالية غير قابلة\n" +
      "للاسترداد في إطار صيغة السكن الريفي",
    body:
      "تسيير عملية منح الاعانة المالية الغير قابلة للاسترداد الممنوحة من\n" +
      "طرف الصندوق الوطني لمعادلة الخدمات الاجتماعية، لفائدة العمال\n" +
      "الاجراء والمتقاعدين المنتسبين للصندوق الوطني للتأمينات\n" +
      "الاجتماعية للعمال الاجراء، المستفيدين من صيغة السكن الريفي\n" +
      "والذين تتوفر فيهم شروط القابلية المعمول بها على مستوى الصندوق.",
  },
  rural_construction: {
    title: "الاعانة المالية في صيغة السكن الريفي",
    body: "تندرج الاعانة المالية في صيغة السكن الريفي في إطار سياسة التنمية الريفية، وتهدف لتنمية هذه المناطق وتثبيت السكان المحليين. وتساهم في تشجيع الأسر على إنجاز سكن لائق في محيطهم الريفي.",
  },
  a_propos_service: {
    title: "حول الخدمة",
    body1:
      "مرحبا بكم على المنصة الرقمية لطلب الإعانة المالية في صيغة السكن الريفي. يمكنكم من خلال هاته المنصة التعرف على الإجراءات الرسمية\n" +
      "والشروط التي تحدد كيفية الاستفادة من الاعانة المالية بصيغة السكن الريفي مع توضيح الفئات المعنية بها. تسمح لكم المنصة بالتسجيل\n" +
      "الأولي، اظهار نتائج المعالجة، استقبال مواعيد لدى الملحقات الولائية للصندوق، و تقديم الطعون عند الاقتضاء، وذلك حرصا من الصندوق\n" +
      "الوطني لمعادلة الخدمات الاجتماعية على التكفل الأفضل بطالبي الاعانة المالية في هذه الصيغة وتقديم خدمات ذات جودة دون عناء\n" +
      "التنقل.",
    body2: "",
    body3: "",
    body4: "",
  },
  rural_construction_montant: {
    title: "مبلغ الاعانة المالية في صيغة السكن الريفي",
    body: " إن مبلغ الاعانة المالية للصندوق الوطني لمعادلة الخدمات الاجتماعية مسقف بـخمسمائة ألف دينار جزائري (500.000 دج) ويكون كتكملة لإعانة الدولة الممنوحة من طرف البنك الوطني للإسكان وكذا المساهمات المالية للمستفيدين.",
  },
  conditions_eligibilite: {
    title: "شروط الاستفادة",
    info: "للاستفادة من الاعانة المالية من صيغة السكن الريفي، يجب أن تتوفر في الأجير أو المتقاعد الشروط التالية: ",
    body: {
      cond1:
        " عند تاريخ التسجيل في المنصة، يجب ان تكون مدة الانتساب للصندوق الوطني\n" +
        "للتأمينات الاجتماعية للعمال الاجراء أكثر من ثلاثة (03) سنوات،\n" +
        "وكذا الشطب من الصندوق الوطني للضمان الاجتماعي لغير\n" +
        "الاجراء منذ ثلاثة (03) سنوات على الأقل عند الاقتضاء. ",
      cond2:
        "يجب ان لا يتجاوز دخل الاسرة ستة (06) مرات الاجر الوطني الأدنى المضمون.",

      cond3:
        " يجب ان يكون مقرر الاستفادة من اعانة الدولة الممنوحة من طرف \n" +
        "\n" +
        "الصندوق الوطني للسكن مؤرخ بعد تاريخ 01 جانفي 2016. \n",

      cond4:
        "البناية محل طلب إعانة الصندوق الوطني لمعادلة الخدمات الاجتماعية يجب أن يكون قد تحصل صاحبها إجباريا على الشطر الثاني من إعانة الصندوق الوطني للسكن. ",
      cond5: {
        title:
          "يجب ان لا تكون البناية محل طلب اعانة الصندوق الوطني لمعادلة " +
          "الخدمات الاجتماعية منتهية تماما بالنسبة لإحدى الأشغال التالية:",
        steps: {
          step1: "السقف;",
          step2: "الجدران الداخلية والخارجية;",
          step3: "تلبيس الجدران الداخلية والخارجية;",
          step4: "بلاط الارضية والجدران;",
        },
      },

      cond6:
        "يجب ان تكون رخصة البناء سارية المفعول عند إيداع الملف " +
        "ويجب ان تصرح ان البناية ذات طابق واحد فقط.",
      important:
        "ان طلبات الاستفادة من اعانة الصندوق الوطني لمعادلة الخدمات الاجتماعية المتعلقة بأي شكل من اشكال التوسعة او بناء على المساحة لاستعمال غير سكني، يتم اقصاؤها ورفضها. ",
    },
  },
  procedures_section: {
    title:
      "الإجراءات المتبعة للاستفادة من الاعانة المالية بصيغة السكن الريفي\n",
    body: {
      step1: {
        info: "التسجيل عبر المنصة",
        steps: {
          stepOne: {
            title: "التأكد من صحة رقم الضمان الاجتماعي",
            desc: "من خلال النقر على ايقونة التسجيل وادخال الرقم الضمان الاجتماعي وتاريخ الميلاد",
            observ: {
              body: "لا يمكن اكمال خطوات التسجيل ادا كان رقم الضمان الاجتماعي غير صحيح أو مسجل به مسبقا.",
            },
          },
          stepTwo: {
            title: "ملئ استمارة المعلومات",
            desc: "يجب ملئ جميع الخانات",
          },
          stepThree: {
            title: "الـتأكد من جميع البيانات والموافقة عليها",
            desc: "يرجي قراءة والتأكد من صحة المعلومات بعناية قبل الموافقة.",
            observ: {
              body: "إن تدوين أي معلومات خاطئة أو مغلوطة وأي تصــريح كاذب من طرف طالب الاعانة يعرض طلبه للرفض وكذا للعقـوبات الواردة في المـادتين 222و 223من قانون العقوبـات",
            },
          },
          stepFour: {
            title: "ارسال معلومات الدخول",
            desc:
              "يتم ارسال معلومات الدخول على المنصة الى الحساب الالكتروني الخاص بطالب الإعانة\n" +
              "يمكنك طبع وصل التسجيل طلبك في الفضاء المخصص لك, إذا كان لديك حساب في المنصة.",
          },
        },
      },
      step2: {
        info: "المراقبة والتحقق ومعالجة المعلومات لطالب الاعانة",
        body:
          "عد انتهاء فترة التسجيل، وبناء على المعلومات المسجل بها عبر المنصة الرقمية تقوم مصالحنا بعملية معالجة اولية لجميع الطلبات من خلال اتباع شروط\n" +
          "الاستفادة المذكور أعلاه، والنتائج المحصل عليها (تنقيط كل مكتتب) يتم ترتيبها من اعلى تنقيط الى اقل تنقيط",
      },
      step3: {
        info: "اظهار نتائج المراقبة",
        body: "بعد الانتهاء من مرحلة المراقبة والتحقق ومعالجة المعلومات يتم على المنصة الرد واخطار كل طالب اعانة بمأل ملفه والنتائج المحصل عليها حسب الحالات التالية:",
        steps: {
          stepOne: {
            title: "مكتتب منتقى أولي",
            desc: "في هاته الحالة يتم ارسال استدعاء لطالب الاعانة للتقدم إلى الملحقة الولائية المختصة إقليميا مرفقا بملف كامل يثبت صحة المعلومات المسجلة على المنصة الرقمية.",
          },

          stepTwo: {
            title: "مكتتب غير مقبول",
            observ: {
              body: " الطعون المقدمة خارج المنصة الرقمية او خارج الآجال المحددة لا يتم اخذها بعين الاعتبار.",
            },
            desc: "في هاته الحالة يتم اخطار طالب الاعانة بسبب أو أسباب عدم القبول وبعدد النقاط المتحصل عليها، بعدها تفتح إمكانية تقديم الطعون خلال 15 يوما التي تلي تاريخ التبليغ على المنصة الرقمية.",
          },
          stepThree: {
            title: "مستفيد",
            desc1:
              "بعد الانتهاء من عملية الدراسة والمراقبة، فإن ملفات المكتتبين المنتقيين أوليا الذين اجتازوا بنجاح جميع مراحل، تعتبر مقبولة ومؤهلة. .",
            desc2:
              "تعرض الملفات على اللجنة المركزية الخاصة للصندوق للمصادقة عليها",
            desc3: "يتم اشعار كل مستفيد من الاعانة الريفية عبر المنصة الرقمية",
          },
        },
      },
      step4: {
        info: "الموافقة على قوائم المستفيدين",
        body: "بعد الانتهاء من عملية الدراسة والمراقبة، فإن ملفات المكتتبين المنتقيين أوليا الذين اجتازوا بنجاح جميع مراحل، تعتبر مقبولة ومؤهلة",
        steps: {
          stepOne: {
            title:
              "الموافقة على قوائم المستفيدين من طرف اللجنة المركزية الخاصة للصندوق",
            body: "تعرض الملفات على اللجنة المركزية الخاصة للصندوق للمصادقة عليها",
          },
          stepTwo: {
            title: "إعلام المستفيدين",
            body: "يتم اشعار كل مستفيد من الاعانة الريفية عبر المنصة الرقمية",
          },
        },
      },
    },
  },

  references_reglementaires: {
    title: "المراجع القانونية والتنظيمية",
    r1: "القانون رقم  16-83المؤرخ في 02 جويلية 1983 المتضمن إنشاء الصندوق الوطني لمــعادلة الخدمات الاجتماعية.",
    r2:
      "المرسوم التنفيذي 75/96 المؤرخ في 03 فبراير 1996 المتضمن كيفيات تنظيم الصندوق الوطني لمعادلة الخدمات الاجتماعية وسيره، المعدل" +
      "والمتمم.",
    r3:
      "المرسوم التنفيذي رقم 235-10 المؤرخ في 05 أكتوبر ،2010 المعدل والمتمم بالمرسوم التنفيذي رقم 06-18 المؤرخ في 20 يناير 2018 الذي يحدد\n" +
      "مستويات المساعدة المباشرة الممنوحة من الدولة لاقتناء سكن جماعي او بناء سكن ريفي او سكن فردي منجز في شكل مجمع في مناطق\n" +
      "محددة في الجنوب والهضاب العليا ومستويات دخل طالبي هذه السكنات وكذا كيفيات منح هذه المساعدات.",
    r4:
      "السياسة العامة للصندوق الوطني لمعادلة الخدمات الاجتماعية المصادق عليها من طرف مجلس الإدارة بتار يخ 13 ماي 1997 وكذا وزارة العمل\n" +
      "والضمان الاجتماعي.",
    r5: "منشور السيد وزير العمل والتشغيل والضمان الاجتماعي رقم 0001 المؤرخ في 04 أكتوبر 2023 الذي يحدد إجراءات تسيير الاعانة المالية غير القابلة للاسترداد الممنوحة من طرف الصندوق الوطني لمعادلة الخدمات الاجتماعية في إطار برنامج السكن الريفي.",
  },
  suivez_nous_sites: "تابعنا على المواقع:\n",
  montant_aide_financiere: "مبلغ الاعانة المالية",
  demarche_suivie: "الإجراءات المتبعة للاستفادة من الإعانة",
  envoyer: "إرسال",
  important: "هام",
  etape: "المرحلة",
  observation: "ملاحظة",
  republique_algerienne: "الجمهورية الجزائرية الديمقراطية الشعبية",
  ministere_du_travail: "وزارة العمل و التشغيل و الضمان الاجتماعي",
  fnpos: "الصندوق الوطني لمعادلة الخدمات الاجتماعية",
  bienvenue_sur_le_portail:
    "مرحبا بكم في بوابة التسجيل لخدمات الصندوق الوطني لمعادلة الخدمات الاجتماعية",
  accueil: "الرئيسية",
  espace_demandeur: "فضاء المستخدم",
  connexion: "الدخول",
  sinscrire: "التسجيل",
  a_propos_du_service: "حول الخدمة",
  contactez_nous: "التواصل معنا",
  reconstitution_de_carriere: "إعادة تأهيل المهني",
  username: "اسم المستخدم",

  npassword: ": أدخل كلمة المرور الجديدة",
  repeat_npassword: ": أعد إدخال كلمة المرور الجديدة",
  recours_success: "لقد تم تسجيل طلبك بنجاح",
  formulaire_connexion: "الدخول لفضاء المستخدم",
  formulaire_mot_de_passe_oublie: "استعادة كلمة المرور",
  formulaire_envoi_confirmation_compte: "إعادة إرسال رابط تفعيل الحساب",
  formulaire_change_email:
    "تغيير البريد الإلكتروني",
  formulaire_activation_compte: "تفعيل الحساب",
  profile_utilisateur: "فضاء المستخدم",
  formulaire_inscription: "إستمارة التسجيل",
  formulaire_inscription_sous_titre1: "تنويه: يرجى الاطلاع على",
  formulaire_inscription_sous_titre2: "قبل الشروع في ملأ الاستمارة",
  formulaire_inscription_link: "شروط الاستفادة",
  formulaire_inscription_description:
    "أدخل جميع المعلومات للإنتقال إلى المرحلة التالية",
  contactez_nous_section: {
    nom_prenom: "الإسم و اللقب",
    sujet: "الموضوع",
    email: "البريد الإلكتروني",
    message: "الرسالة",
    message_succes: "تم إرسال رسالتك بنجاح",
  },
  operation_effectuee: "تمت العملية بنجاح",
  operation_echouee: "فشل العملية",
  error: "خطأ",
  login: {
    numInscription: "رقم التسجيل",
    password: "كلمة المرور",
    login: "تسجيل الدخول",
    error: "خطأ أثناء تسجيل الدخول",
    error_creds: "رقم التسجيل أو كلمة المرور غير صحيحة",
    error_reset_password_email_not_found: "البريد الإلكتروني غير مسجل",
    mot_de_passe_oublie: "نسيت كلمة المرور",
    non_inscrit: "أنت لست مسجلا بعد ؟ أنقر هنا",
    non_confirmer: "لم يصلك رابط تفعيل الحساب ؟ إضغط هنا",
    reset_password: "إعادة تعيين كلمة المرور",
    reset_password_success:
      "تم إرسال رسالة إلى بريدك الإلكتروني لإعادة تعيين كلمة المرور",
    reset_password_done: "تم إعادة تعيين كلمة المرور الخاصة بك بنجاح",
  },

  inscription: {
    confirmation: {
      title: "الـتأكد من جميع البيانات والموافقة عليها",
      info: "يرجي قراءة والتأكد من صحة المعلومات بعناية قبل الموافقة. ",
      desc: "بمجرد ارسال الاستمارة، لن يكون لديك الحق في تعديل معلوماتك. ",
      remarque:
        "أي تصــريح كاذب من طرف طالب الاعانة يعرضه للرفض و العقـوبات الواردة في المـادتين 222 و 223 من قانون العقوبـات",
      confirm: "أؤكد معلوماتي",
      confirmation_message:
        "أصرح بشرفي انني امنح الصندوق الوطني لمعادلة الخدمات الاجتماعية موافقتي الصريحة على معالجة معطياتي ذات الطابع الشخصي وهذا طبقا لأحكام القانون 18-07 المؤرخ في 10 جوان 2018 المتعلق بحماية الأشخاص الطبيعيين في مجال معالجة المعطيات ذات الطابع الشخصي، وإنني قد اعلمت بالحقوق الممنوحة لي لاسيما الحق في الاعلام، الحق في الولوج، الحق في التصحيح قبل الموافقة على المعلومات و تأكيدها والحق في الاعتراض.",
    },
    confirmation_email: {
      success: "تم تفعيل حسابك بنجاح",
      loading: "جاري تفعيل حسابك ...",
      error: "حدث خطأ أثناء تفعيل حسابك",
      invalide: "هذا الرابط غير صالح للتسجيل",
    },
    steps: {
      ren_personel: "معلومات شخصية",
      iss: "معلومات الضمان الاجتماعي",
      isc: "معلومات الزوج(ة)",
      formulaire_candidature: "استمارة الطلب",
      validation_donnees: "تأكيد البيانات",
    },
    confirmation_success: {
      title: "تم التسجيل بنجاح",
      subTitle: "ستصلك رسالة بالبريد الإلكتروني تحتوي على رابط تفعيل الحساب",
      go_back: "الرجوع إلى الصفحة الرئيسية",
    },

    sinscrire: "التسجيل",
    suivant: "التالي",
    precedent: "السابق",
    sexe: "الجنس",
    presume: "مفترض",
    situation_familiale: "الحالة العائلية",
    nbr_enfants: "عدد الأولاد",
    nbr_enfants_30ans: "عدد الأطفال دون سن 30",
    situation_professionnelle: "الوضعية المهنية",
    mois: "أشهر",
    annees: "سنوات",
    revenu_mensuel: "الدخل الشهري",
    experience_professionnelle: "الخبرة المهنية",
    nom_latin: "اللقب باللاتنية",
    nom_arabe: "اللقب بالعربية",
    prenom_latin: "الاسم باللاتينية",
    prenom_arabe: "الاسم بالعربية",
    adress_construction_ar: " عنوان البناية بالعربية",
    adress_construction_lt: "عنوان البناية باللاتينية",
    date_naissance: "تاريخ الميلاد",
    wilaya_naissance: "ولاية الميلاد",
    commune_naissance: "بلدية الميلاد",
    n_acte_naissance: "رقم شهادة الميلاد",
    nss: "رقم الضمان الاجتماعي",
    nin: "رقم التعريف الوطني",
    prenom_pere_lt: "اسم الأب باللاتينية",
    prenom_pere_ar: " اسم الأب بالعربية",
    nom_mere_lt: "لقب الأم باللاتينية",
    nom_mere_ar: "لقب الأم بالعربية",
    prenom_mere_lt: "اسم الأم بالاتينية",
    prenom_mere_ar: "اسم الأم بالعربية",
    n_tel: "رقم الهاتف",
    courriel: "البريد الإلكتروني",
    dinars: "دج",
    demande_aide_pour: "طلب مساعدة من أجل",
    date_decision_cnl: " تاريخ قرار BNH (ex.CNL)",
    numero_decision_cnl: " رقم قرار BNH (ex.CNL)",
    wilaya_projet: "ولاية المشروع",
    commune_projet: "بلدية المشروع",
    // deuxieme_tranche_versee: "دفع الشطر الثاني",
    deuxieme_tranche_versee: " الشطر الثاني من اعانة BNH (ex.CNL)",
    projet_finalise: "تم إنجاز الأشغال 100%",
    date_delivance_permis_construction: "تاريخ اصدار رخصة البناء",
    oui: "نعم",
    non: "لا",
    tooltip_deuxieme_tranche_versee:"تحصلت على الشطر الثاني من اعانة BNH (ex.CNL)",
    tooltip_projet: "في حالة نسبة الأشغال لم تتجاوز %100 يرجى النقر على لا",
  },
  sexe: {
    femme: "أنثى",
    homme: "ذكر",
  },
  situation_familiale: {
    celibataire: "أعزب/عزباء",
    marie: "متزوج(ة)",
    divorce: "مطلق(ة)",
    veuf: "أرمل(ة)",
  },
  situation_professionnelle: {
    salarie: "عامل أجير",
    retraite: "متقاعد",
  },
  situation_professionnelle_conjoint: {
    actif: "عامل أجير أو متقاعد",
    sans: "دون نشاط",
    militaire: "نشاط آخر",

  },
  statut: {
    encours: "قيد الدراسة",
    eligible: "مكتتب منتقى أولي",
    rejete: "مكتتب غير مقبول",
    mise_en_attente: "مستفيد",
  },
  statut_recours: {
    encours: "قيد الدراسة",
    eligible: "طعن مؤسس",
    rejete: "طعن غير مؤسس",
  },
  counter: {
    day: "يوم",
    hour: "ساعة",
    minute: "دقيقة",
    second: "ثانية",
  },
  messages: {
    change_email_important:"هذه الخاصية تسمح بتغيير البريد الإلكتروني الخاطئ للحسابات التي لم تصلها رسالة الكترونية لتفعيل حساباتهم نرجو منكم إدخال المعلومات الصحيحة المسجل بها مسبقا و إدخال و تأكيد حساب إلكتروني جديد ليتسنى لنا إرسال رابط تفعيل الحساب .",
    champ_obligatoire: "هذا الحقل إجباري",
    email_invalide: "البريد الإلكتروني غير صحيح",
    tel_invalide: "رقم الهاتف غير صحيح",
    nin_invalide: "رقم التعريف الوطني غير صحيح",
    nss_invalide: "رقم الضمان الاجتماعي غير صحيح",
    ncnl_invalide:"رقم BNH (ex.CNL) غير صحيح",
    ncnl_17chiffre:" يجب أن يحتوي رقم BNH (ex.CNL) على 17 رقمًا بالضبط",
    presume_invalide: "يرجى تحديد الأول من جانفي إذا كان تاريخ الميلاد مفترضًا",
    nbr_enfants_invalide: "عدد الأولاد يجب أن يكون بين 1 و 20",
    revenu_mensuel_invalide: "الدخل الشهري يجب أن يكون بين 0.00 و 999999.00",
    nss_exist: "رقم الضمان الاجتماعي مسجل مسبقا",
    nss_beneficiare:"رقم الضمان الإجتماعي مستفيد سابقا",
    email_exist: "البريد الإلكتروني مسجل مسبقا",
    date_invalide: "الرجاء ادخال تاريخ صحيح",
    date_cnl_invalide: "يرجى إدخال تاريخ بعد:",
    max_length: "يجب أن لا يتجاوز تبريرك الأ لفي كلمة",
    not_same_newpass: "كلمتا المرور اللتان أدخلتهما غير متطابقتين",
    invalide_pass:
      "يجب أن يكون طول كلمة المرور أكبر من 6 أحرف وأن تحتوي على الأقل على حرف كبير وحرف صغير ورقم وحرف خاص",
  },
  footer: {
    droit_reserve: "جميع الحقوق محفوظة",
  },
  etapes: {
    securite_sociale: "الضمان الاجتماعي",
    informations_personnelles: "المعلومات الشخصية",
    informations_conjoint: " معلومات الزوج(ة)",
    demande: "استمارة الطلب",
    confirmation: "تأكيد الطلب",
    recours: "الطعن",
  },
  parcourir: "تصفح",
  fichier_justification: "ملف التبرير",
  telecharger_fichier: "تنزيل الملف",
  taille_fichier: "يجب ألا يتجاوز حجم الملف 1 ميغا بايت",
  type_fichier:
    "ـ يجب أن تكون المرفقات مستندات ممسوحة ضوئيا بتنسيق JPEG أو PDF.",
  justification: "التبرير",
  joinder_fichier: "إرفاق الملف (JPEG أو PDF) :",
  demande_recours: "تقديم الطعن",
  telecharger_convocation: "تنزيل الاستدعاء",
  telecharger_decision: "تنزيل القرار",
  telecharger_formulaire: "تنزيل وصل التسجيل",
  deconnexion: "خروج",
  notes: {
    note: "نقطة",
    note_anciennete_professionnelle: "معيار الأقدمية المهنية",
    note_revenu_menage: "معيار المدخول",
    note_situation_familiale: "معيار الوضعية العائلية",
    nbr_dateCNL: "معيار أقدمية تاريخ استصدار ص و س",
    note_total: "النقطة الإجمالية",
  },
  notFound: {
    desole_page_exist_pas: "عذرا ، الصفحة التي زرتها غير موجودة.",
  },
  backend_messages: {
    InvalidGoogleReCaptcha: "الكابتشا غير صالح",
    InvalidToken: "الرمز غير صالح",
    EmailUnfound:
      "لم تستخدم عنوان البريد الإلكتروني هذا أثناء التسجيل ، يرجى استخدام عنوان البريد الإلكتروني للتسجيل",
    EmailAlreadyExists: "هذا البريد الإلكتروني مسجل بالفعل",
    InvalidWilaya: "الولاية غير موجودة",
    UnsupportedCulture: "اللغة غير مدعومة",
    RecoursDescriptionRequired: "تبرير طعنك إلزامي",
    RecousFileNameRequired: "الملف مطلوب",
    RecoursFileMustBeInFormat_PDF_JPG_JPEG:
      "PDF أو JPG أو JPEG يجب أن يكون امتداد الملف",
    InscriptionNumberIsRequired: "رقم التسجيل إجباري",
    NssAlreadyExist: "رقم الضمان الاجتماعي مسجل بالفعل",
  },
  captcha_error: "خطأ في الاتصال بالأنترنيت",
  envoi_confirmation_account_success:
    "تم إعادة إرسال رابط تفعيل الحساب إلى بريدكم الإلكتروني بنجاح",
  Email_Confirmed: "حسابك مفعل بالفعل يرجى تسجيل الدخول",
  Nss_Not_Exist:
    "هذا الحساب غير موجود يرجى التأكد من المعلومات أو تسجيل الدخول",
  counter_down_title: "بقي لتسجيل طلبكم عبر هذه المنصة:",
  nouveau_email: "البريد الإلكتروني الجديد",
  confirm_nouveau_email: "تأكيد البريد الإلكتروني الجديد",
  Wrong_Information:"يرجى التأكد من المعلومات المدخلة",

  btn_edit_demande: "تصحيح استمارة الطلب",
  btn_edit_demandeur: "تصحيح المعلومات الشخصية ",
  btn_edit_conjoint: "تصحيح معلومات الزوج(ة)",
  btn_create_conjoint: "إضافة معلومات الزوج(ة)",

  modal_edit_demandeur: "تصحيح المعلومات الشخصية",
  modal_edit_conjoint: " تصحيح معلومات الزوج(ة)",
  modal_create_conjoint: "إضافة معلومات الزوج(ة)",
  modal_edit_demande: " تصحيح استمارة الطلب ",
  modal_btn_save: "تأكيد",
  popup_title_success: "نجحت عملية التعديل",
  popup_message_success_demandeur: " لقد تم تعديل معلوماتك الشخصية بنجاح ",
  popup_message_success_conjoint: "لقد تم تعديل معلومات الزوج(ة) بنجاح ",
  popup_message_success_demande: "لقد تم تعديل معلومات طلبك بنجاح ",
  popup_title_error: "فشلت عملية التعديل",
  popup_message_error_demandeur: " لم يتم تعديل معلوماتك الشخصية ",
  popup_message_error_conjoint: "لم يتم تعديل معلومات الزوج(ة)  ",
  popup_message_error_demande: "لم يتم تعديل معلومات طلبك ",
  popup_title_add_conjoint_success: "تمت إضافة الزوج(ة) بنجاح",
  popup_message_add_conjoint_success: "لقد تم إضافة معلومات الزوج(ة) بنجاح، يرجى أن تقوموا بإكمال وتصحيح المعلومات الشخصية التالية: عدد الأولاد وعدد الأطفال دون سن 30 ",
  popup_title_add_conjoint_error: "فشلت عملية إضافة الزوج(ة)",
  popup_message_add_conjoint_error: "لم يتم إضافة معلومات الزوج(ة)",
  confirmation_delete_conjoint_modal_content:"لقد قمت بتغيير حالتك العائلية، وعليه سيتم مسح معلومات الزوج(ة)، هل أنت متأكد من المعلومات المدخلة ؟",
  confirmation_delete_conjoint_modal_title:"مسح معلومات الزوج(ة)",
  btn_confirmer: "تأكيد",
  Conjoint_Not_Defined:"يرجى إضافة معلومات الزوج(ة) أولاً، ثم إكمال المعلومات الشخصية ذات الصلة."

};

const lang = { translation: values };
export default lang;
