const values = {
  profile: {
    num_inscription: "Numéro d'inscription",
    date_inscription: "Date d'inscription",
    date_recours: "Date recours",
    statut_demande: "Statut de la demande",
    statut_recours: "Statut du recours",
    date_rdv: "Date rendez-vous",
    plage: "À",
  },
  navbar: {
    ministere_du_travail: "Ministère du Travail , de l'Emploi",
    emploi: "et de la Sécurité Sociale",
    fnpos1: "Fonds National de Péréquation",
    fnpos2: "des Oeuvres Sociales",
    bienvenue: "Bienvenue",
  },
  formulaire_reset_password: "Réinitialisation du mot de passe",
  full_page_header: {
    title:
      "Plateforme d’inscription en ligne des demandes\n" +
      "des Salariés et Retraités\n" +
      "pour le bénéfice d’une aide financière non\n" +
      "remboursable dans le cadre de l’habitat rural",
    body:
      "L’attribution et de mise en œuvre de l’aide FNPOS, \n" +
      "\n" +
      "Au profit des salariés en activité ou en retraite, bénéficiaires de logement, initié dans le cadre du dispositif de l’habitat rural et remplissant les critères ",
  },
  rural_construction: {
    title: "Aide financière à l’habitat rural",
    body: "L’aide à l’habitat rural s’inscrit dans la politique nationale de développement rural et vise à faire évoluer les zones rurales et à en fixer les populations locales, en encourageant les familles à construire des logements décents dans leur environnement naturel.",
  },
  a_propos_service: {
    title: "A propos de la prestation",
    body1:
      "Bienvenus sur la plateforme numérique de demande d’aide financière\n" +
      "non remboursable à l’habitat rural.",
    body2:
      "Grâce à cette plateforme, vous pouvez prendre connaissance de la\n" +
      "procédure officielle et des conditions à remplir pour bénéficier de l’aide\n" +
      "financière à l’habitat rural, ainsi que les catégories de personnes\n" +
      "concernées par la dite aide.",
    body3:
      "La plateforme vous permet, dans un premier temps, de procéder à votre pré-inscription, de consulter les résultats obtenus après traitement, d’obtenir des rendez-vous auprès des Annexes de wilaya, et de formuler des recours le cas échéant.",
    body4:
      "Ceci permettra au FNPOS de s’assurer de la meilleure prise en charge des demandeurs d’aide financière à l’habitat rural, en leur offrant un service de qualité et en leur évitant les désagréments liés à leur déplacement auprès des structures du Fonds.",
  },
  rural_construction_montant: {
    title: "Montant de l’aide à l’habitat rural\n",
    body: "Le montant de l’aide FNPOS est plafonné à cinq cent mille dinars Algériens (500 000 DA) et vient en complément de l’aide de l’Etat octroyée par la Banque Nationale du Logement (ex BNH (ex.CNL)), ainsi que des apports financiers des bénéficiaires.",
  },
  conditions_eligibilite: {
    title: "Conditions d’éligibilité",
    info:
      "Pour bénéficier de l'aide financière à l’habitat rural, le salarié\n" +
      "ou le retraité doit remplir les conditions suivantes: ",
    body: {
      cond1:
        "A la date d’inscription sur la plateforme, la durée de cotisation à la CNAS, doit être supérieure à 3 années, et la résiliation de son affiliation à la CASNOS depuis 3 années au moins, le cas échéant ;",
      cond2:
        "Le revenu mensuel du ménage ne doit pas dépasser 6\n" +
        "fois le SNMG ;",
      cond3:
        "La décision d’octroi de l’aide de l’Etat délivrée par BNH (ex.CNL), doit être datée, postérieurement, au 1er Janvier 2016 ;",
      cond4:
        "La construction, objet de la demande d’aide FNPOS, doit obligatoirement, avoir bénéficié du paiement de la 2ème tranche de l’aide BNH (ex.CNL) ;",
      cond5: {
        title:
          "la construction, objet de la demande d’aide FNPOS, ne doit pas être " +
          "achevée pour les travaux de l’un des lots suivants :",
        steps: {
          step1: "Plancher ;",
          step2: "Maçonnerie intérieure et extérieure ;",
          step3: "Enduits intérieurs et extérieurs ;",
          step4: "Revêtement du sol et revêtement mural ;",
        },
      },

      cond6:
        "Le permis de construire doit être en cours de validité au " +
        "moment de dépôt du dossier et doit correspondre à la " +
        "construction d’un seul niveau.",
      important:
        "Les demandes d’aide FNPOS, liées à toutes formes d’extension et/ou autres constructions de surfaces, dont l’usage est autre que l’habitat, sont rejetées et refusées.",
    },
  },
  procedures_section: {
    title:
      "Modalités liées au bénéfice de l'aide financière\n" +
      "à l’habitat rural",
    body: {
      step1: {
        info: "Inscription en ligne",
        steps: {
          stepOne: {
            title: "Vérification du numéro de sécurité sociale",
            desc: "En cliquant sur l’icone d’inscription et en saisissant le numéro de sécurité sociale et la date de naissance.",
            observ: {
              body: "Il n’est pas possible de poursuivre les étapes d’inscription si le numéro de sécurité sociale est erroné ou déjà enregistré.",
            },
          },
          stepTwo: {
            title: "Renseignement du formulaire",
            desc: "Tous les champs doivent être renseignés avec soin.",
          },
          stepThree: {
            title: "Vérification et approbation de toutes les données.",
            desc: "Veuillez lire et vérifier attentivement les informations avant confirmation des données.",
            observ: {
              body: "La mention de toutes informations erronées et/ou de toute fausse déclaration, par le demandeur d’aide à l’habitat rural, expose ce dernier au rejet de sa demande, ainsi qu’aux sanctions prévues aux articles 222 et 223 du Code pénal.",
            },
          },
          stepFour: {
            title: "Transmision des informations d’accès de la plateforme",
            desc: "Les informations d’accès à la plateforme sont transmises via le compte électronique du demandeur de l’aide. Si vous avez un compte sur la plateforme, il vous est possible d’imprimer le reçu d’inscription.",
          },
        },
      },
      step2: {
        info: "Contrôle, vérification et traitement des données liées au demandeur de l’aide",
        body: "A l’issue de la phase d’inscription, et sur la base des informations enregistrées par les demandeurs sur la plateforme numérique, nos services procéderont à un traitement préalable de toutes les demandes, suivant les conditions d’éligibilité mentionnées ci-dessus, les résultats obtenus (la note obtenue par chaque demandeur) sont classés dans l’ordre dégressif.",
      },
      step3: {
        info: "Affichage des résultats du contrôle",
        body:
          "A l’issue de la phase de contrôle, vérification et traitement des données, chaque demandeur d’aide sera informé\n" +
          "des résultats qu’il a obtenu, via la plateforme numérique , selon les cas ci-après :",
        steps: {
          stepOne: {
            title: " Souscripteur présélectionné",
            desc: "Dans ce cas, il lui est adressé une convocation pour sa présentation au niveau de l'Annexe de Wilaya, muni du dossier complet, prouvant la conformité des informations enregistrées sur la plateforme.",
          },
          stepTwo: {
            title: "Souscripteur rejeté",
            desc: "Dans ce cas, le demandeur est informé du nombre de points obtenus, du motif du rejet, et de la possibilité de formuler un recours en ligne, dans un délai maximal de 15 jours à compter de la date de notification du rejet sur la plateforme.",
            observ: {
              body: "les recours formulés, en dehors des délais requis et/ou hors Plateforme, ne sont pas pris en compte.",
            },
          },

          stepThree: {
            title: "Bénéficiaire",
            desc1:
              "A l’issue des phases de traitement, de contrôle et vérification des données, les dossiers des souscripteurs présélectionnés ayant franchi avec succès toutes lesdites phases, sont considérés comme recevables et éligibles.",
            desc2:
              "Lesdits dossiers sont soumis à la Commission Centrale Adhoc pour approbation.",
            desc3:
              "Chacun des bénéficiaires de l’aide à l’habitat rural sera informé via la plateforme.",
          },
        },
      },
      step4: {
        info: "Validation des listes des Bénéficiaire",
        body: "A l’issue des phases de traitement, de contrôle et vérification des données, les dossiers des souscripteurs présélectionnés ayant franchi avec succès toutes lesdites phases, sont considérés comme recevables et éligibles.",
        steps: {
          stepOne: {
            title: "Approbation des listes des bénéficiaires par CC - Adhoc",
            body: "Lesdits dossiers sont soumis à la Commission Centrale Adhoc pour approbation.",
          },
          stepTwo: {
            title: "Informer les bénéficiaires",
            body: "Chacun des bénéficiaires de l’aide à l’habitat rural sera informé via la plateforme.",
          },
        },
      },
    },
  },
  references_reglementaires: {
    title: "Références règlementaires\n",
    r1:
      "Loi N° 83-16 du 2 Juillet 1983, portant création du Fonds National de\n" +
      "Péréquation des Œuvres Sociales ;",
    r2:
      "Décret exécutif N° 96-75 du 03 Février 1996, modifié et complété, portant\n" +
      "Modalités d’organisation et de fonctionnement du FNPOS ;",
    r3:
      "Décret exécutif N° 10-235 du 5 octobre 2010, modifié et complété par le\n" +
      "décret exécutif N°18-06 du 20 janvier 2018, fixant les niveaux de l’aide\n" +
      "frontale octroyée par l’État pour l’accession à la propriété d’un logement\n" +
      "collectif ou pour la construction d’un logement rural, ou d’un logement\n" +
      "individuel réalisé sous forme groupée dans les zones définies du sud et\n" +
      "des hauts-plateaux, les niveaux de revenu des postulants à ces\n" +
      "logements ainsi que les modalités d’octroi de cette aide ;",
    r4:
      "Politique générale du FNPOS, approuvé par le Conseil d’Administration\n" +
      "du 13 Mai 1997, ainsi que le Ministère de l’Emploi du Travail et de la Sécurité Sociale ;",
    r5: "Circulaire du Monsieur le Ministre du Travail, de l’Emploi et de la Sécurité Sociale n° 0001 du 04 octobre 2023 fixant la procédure de mise en œuvre de l’aide du Fonds National de Péréquation des Œuvres Sociales (FNPOS) à l'habitat rural ;",
  },
  suivez_nous_sites: "Suivez-nous sur les sites :",
  montant_aide_financiere: "Montant de l'aide financière",
  demarche_suivie: "Modalités liées au bénéfice de l'aide",
  envoyer: "Envoyer",
  important: "Important",
  etape: "ÉTAPE",
  observation: "Remarque",
  republique_algerienne: "République Algérienne Démocratique et Populaire",
  ministere_du_travail:
    "Ministère du Travail , de l'Emploi et de la Sécurité Sociale",
  fnpos: "Fonds National de Péréquation des Oeuvres Sociales",
  bienvenue_sur_le_portail:
    "Bienvenue sur le portail d'inscription aux prestations du Fonds National de Péréquation des Oeuvres Sociales",
  accueil: "ACCUEIL",
  connexion: "CONNEXION",
  espace_demandeur: "Espace Souscripteur",
  sinscrire: "S'INSCRIRE",
  a_propos_du_service: "À PROPOS DU SERVICE",
  a_propos_du_service_menu: "À propos du service",
  contactez_nous: "CONTACTEZ-NOUS",
  reconstitution_de_carriere: "RECONSTITUTION DE CARRIÈRE",
  username: "Nom d'utilisateur",
  npassword: "Nouveau mot de passe :",
  repeat_npassword: "Répétez le nouveau mot de passe",
  recours_success: "Votre recours a été enregistré avec succès",

  formulaire_connexion: "Accès à l’espace souscripteur",
  formulaire_mot_de_passe_oublie: "Récuperer le mot de passe",
  formulaire_envoi_confirmation_compte:
    "Renvoyer le lien d'activation du compte",
    formulaire_change_email:
    "Changer l'email",
  formulaire_activation_compte: "Activation du compte",
  formulaire_inscription: "Formulaire d'inscription",
  formulaire_inscription_sous_titre1:
    "À noter : Veuillez consulter la rubrique",
  formulaire_inscription_sous_titre2: "avant de remplir le formulaire",
  formulaire_inscription_link: "Conditions d'éligibilité",
  profile_utilisateur: "ESPACE SOUSCRIPTEUR",
  formulaire_inscription_description:
    "Veuillez saisir toutes les informations pour passer à l'étape suivante",
  contactez_nous_section: {
    nom_prenom: "Nom et prenom",
    sujet: "Sujet",
    email: "Email",
    message: "Message",
    message_succes: "Votre message a été envoyé avec succès",
  },
  operation_effectuee: "Opération effectuée",
  operation_echouee: "Opération échouée",
  error: "Erreur",
  login: {
    numInscription: "Numéro d'inscription",
    password: "Mot de passe",
    login: "Se connecter",
    error: "Un problème est survenu lors de la connexion",
    error_creds: "Numéro d'inscription ou mot de passe incorrect",
    error_reset_password_email_not_found:
      "Aucun compte n'est associé à cette adresse email",
    mot_de_passe_oublie: "Mot de passe oublié ?",
    non_inscrit: "Vous n'êtes pas inscrit ?",
    non_confirmer: "Vous n'avez pas activer votre compte ?",
    reset_password: "Réinitialisation du mot de passe",
    reset_password_success:
      "Vous allez recevoir un email contient le lien de réinitialisation de votre mot de passe, merci d'accéder à votre boite email et cliquez sur le lien.",
    reset_password_done: "Votre mot de passe a été réinitialisé avec succès",
  },
  inscription: {
    confirmation: {
      title: "Vérification et approbation de toutes les données.",
      desc: "Une fois que vous aurez valider le formulaire, vous n'aurez pas le droit de modifier vos informations.",
      info: "Veuillez lire et vérifier attentivement les informations avant d'accepter.",
      remarque:
        "Toute fausse déclaration du demandeur de l’aide l'expose au rejet de sa demande et aux peines prévues aux articles 222 et 223 du Code pénal.",
      confirm: "Je confirme mes informations",
      confirmation_message:
        "Je déclare sur l’honneur donner mon consentement au F.N.P.O.S pour le traitement de mes données à caractère personnel, et ce conformément aux dispositions de la loi 18-07 du 10 Juin 2018, relative à la protection des personnes physiques dans le traitement des données à caractère personnel, et avoir été informé de mes droits en la matière et notamment le droit à l’information, le droit d’accès, le droit de rectification avant d’accepter et de confirmer les informations et le droit d’opposition",
    },
    confirmation_email: {
      success: "Votre compte a été activé avec succès",
      loading: "Votre compte est en cours d'activation..",
      error: "Une erreur s'est produite lors de l'activation de votre compte",
      invalide: "Ce lien n'est pas valable pour l'inscription",
    },
    steps: {
      ren_personel: "Informations personnelles",
      iss: "Numéro de sécurité sociale",
      isc: "Informations du conjoint",
      formulaire_candidature: "Informations de la demande",
      validation_donnees: "Confirmation",
    },
    confirmation_success: {
      title: "Inscrit avec succès",
      subTitle:
        "Vous recevrez un e-mail contenant un lien d'activation de compte",
      go_back: "Retourner à la page d'accueil.",
    },
    sinscrire: "S'INSCRIRE",
    suivant: "SUIVANT",
    precedent: "PRÉCÉDENT",
    sexe: "Sexe",
    presume: "Présumé",
    situation_familiale: "Situation familiale",
    nbr_enfants: "Nombre d'enfants",
    nbr_enfants_30ans: "Nombre d'enfants moins de 30",
    situation_professionnelle: "Situation professionnelle",
    revenu_mensuel: "Revenu mensuel",
    experience_professionnelle: "Expérience professionnelle",
    mois: "Mois",
    annees: "Années",
    nom_latin: "Nom en latin",
    nom_arabe: "Nom en arabe",
    prenom_latin: "Prénom en latin",
    adress_construction_ar: "Adresse de la construction en arabe ",
    adress_construction_lt: "Adresse de la construction en latin ",
    prenom_arabe: "Prénom en arabe",
    date_naissance: "Date de naissance",
    wilaya_naissance: "Wilaya de naissance",
    commune_naissance: "Commune de naissance",
    n_acte_naissance: "N° d'acte de naissance",
    nss: "N° de sécurité sociale",
    nin: "N° d'identification national",
    prenom_pere_lt: "Prénom du père en latin",
    prenom_pere_ar: "Prénom du père en arabe",
    nom_mere_lt: "Nom de la mère en latin",
    nom_mere_ar: "Nom de la mère en arabe",
    prenom_mere_lt: "Prénom de la mère en latin",
    prenom_mere_ar: "Prénom de la mère en arabe",
    n_tel: "N° de téléphone",
    courriel: "Email",
    dinars: "DA",
    demande_aide_pour: "Demande d'aide pour",
    date_decision_cnl: "Date de décision BNH (ex.CNL)",
    numero_decision_cnl: "N° de décision BNH (ex.CNL)",
    wilaya_projet: "Wilaya du projet",
    commune_projet: "Commune du projet",
    // deuxieme_tranche_versee: "Deuxième tranche versée",
    deuxieme_tranche_versee: "deuxième tranche BNH (ex.CNL)",
    projet_finalise: "Les travaux ont été achevés à 100%",
    date_delivance_permis_construction:
      "Date de délivrance du permis de construction",
    oui: "Oui",
    non: "Non",
    tooltip_deuxieme_tranche_versee:"J'ai reçu la deuxième tranche BNH (ex.CNL)",
    tooltip_projet:
      "Dans le cas où le taux de la réalisation du projet n’est pas achevé à 100% veuillez cliquer sur Non.",
  },
  sexe: {
    femme: "Femme",
    homme: "Homme",
  },
  situation_familiale: {
    celibataire: "Célibataire",
    marie: "Marié (e)",
    divorce: "Divorcé (e)",
    veuf: "Veuf(ve)",
  },
  situation_professionnelle: {
    salarie: "Salarié(e)",
    retraite: "Retraité(e)",
  },
  situation_professionnelle_conjoint: {
    actif: "Actif(ve) ou Retraité(e)",
    sans: "Sans activité",
    militaire: "Autre activité",

  },
  statut: {
    encours: "En cours d'étude",
    eligible: "Souscripteur présélectionné",
    rejete: "Souscripteur rejeté",
    mise_en_attente: "Bénéficiaire",
  },
  statut_recours: {
    encours: "En cours d'étude",
    eligible: "Fondé",
    rejete: "Non Fondé",
  },
  counter: {
    day: "Jours",
    hour: "Heures",
    minute: "Minutes",
    second: "Secondes",
  },
  messages: {
    change_email_important:"Cette fonctionnalité permet de corriger les adresses e-mail erronées pour les demandeurs qui n'ont pas reçu de message électronique pour activer leurs comptes. Nous vous prions d'entrer les informations correctes déjà enregistrées, puis de saisir et de confirmer une nouvelle adresse e-mail afin que nous puissions vous envoyer le lien d'activation du compte.",
    champ_obligatoire: "Ce champ est obligatoire",
    email_invalide: "Email invalide",
    tel_invalide: "Numéro de téléphone invalide",
    nin_invalide: "Numéro d'identification national invalide",
    presume_invalide:
      "Veuillez spécifier le premier janvier si la date de naissance est présumée",
    nss_invalide: "Numéro de sécurité sociale invalide",
    ncnl_invalide: "Numéro BNH (ex.CNL) invalide",
    ncnl_17chiffre:"Le Numéro BNH (ex.CNL) doit contenir exactement 17 chiffres",
    nbr_enfants_invalide: "Nombre d'enfants doit être entre 1 et 20",
    revenu_mensuel_invalide: "Revenu mensuel doit être entre 0.00 et 999999.00",
    nss_exist: "Numéro de sécurité sociale déjà inscrit",
    nss_beneficiare:"Numéro de sécurité sociale déjà bénéficiaire",
    email_exist: "Email déjà inscrit",
    date_invalide: "Date invalide",
    date_cnl_invalide: "Veuillez tapez une date après : ",
    max_length: "Le texte doit ne pas dépasser les 2000 caractères",
    not_same_newpass:
      "Les deux mots de passe que vous avez saisis ne correspondent pas",
    invalide_pass:
      "Le mot de passe doit contenir au minimum 6 caractères avec une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial",
  },
  footer: {
    droit_reserve: "Tous droits réservés",
  },
  etapes: {
    securite_sociale: "Sécurité sociale",
    informations_personnelles: "Informations personnelles",
    informations_conjoint: "Informations du conjoint",
    demande: "Demande",
    confirmation: "Confirmation",
    recours: "Recours",
  },
  parcourir: "Parcourir",
  justification: "Justification:",
  fichier_justification: "Fichier de justification",
  telecharger_fichier: "Télécharger le fichier",
  joinder_fichier: "Joinder le fichier (JPEG,PDF):",
  taille_fichier: "Le fichier ne doit pas dépasser la taille de 1 Mo",
  type_fichier:
    "- Les pièces jointes doivent être des documents scannés au format JPEG ou PDF.",
  demande_recours: "Demande de recours",
  telecharger_convocation: "Télécharger la convocation",
  telecharger_decision: "Télécharger la décision",
  telecharger_formulaire: "Télécharger le reçu d'inscription",
  deconnexion: "Déconnexion",
  notes: {
    note: "Points",
    note_anciennete_professionnelle: "Note Ancienneté Professionnelle",
    note_revenu_menage: "Note Revenu Ménage",
    note_situation_familiale: "Note Situation Familiale",
    nbr_dateCNL: "Note Date Décision BNH (ex.CNL)",
    note_total: "Note totale",
  },
  notFound: {
    desole_page_exist_pas:
      "Désolé, la page que vous avez visitée n'existe pas.",
  },
  backend_messages: {
    InvalidGoogleReCaptcha: "Le captcha est non valide",
    InvalidToken: "le Token est invalide",
    EmailUnfound:
      "Vous n'avez pas utiliser cette adresse mail durant votre inscription, merci d'utiliser l'adresse mail d'inscription",
    EmailAlreadyExists: "Ce mail est déjà existe",
    InvalidWilaya: "La wilaya non valide",
    UnsupportedCulture: "La langue n'est pas supportée",
    RecoursDescriptionRequired:
      "La justification de votre recours est obligatoire",
    RecousFileNameRequired: "Le fichier est obligatoire",
    RecoursFileMustBeInFormat_PDF_JPG_JPEG:
      "Le fichier doit être sous format PDF, JPG ou JPEG",
    InscriptionNumberIsRequired: "Le numéro d'inscription est obligatoire",
    NssAlreadyExist: "Numéro de sécurité sociale déjà inscrit",
  },
  captcha_error: "Erreur de connexion",
  envoi_confirmation_account_success:
    "Le lien d'activation du compte a été renvoyé à votre adresse e-mail avec succès",
  Email_Confirmed: "Votre compte est déjà activé, veuillez vous connecter.",
  Nss_Not_Exist:
    "Ce compte n'existe pas. Veuillez vérifier les informations ou vous connecter.",
  counter_down_title:
    "Il vous reste pour s'inscrire votre demande sur cette plateforme:",
    nouveau_email: "Nouveau email",
    confirm_nouveau_email: "Confirmer le nouveau email",
    Wrong_Information:"Veuillez vérifier les informations saisies",
    btn_edit_demande: "Correction informations de la demande",
    btn_edit_demandeur: "Correction informations personnelles ",
    btn_edit_conjoint: "Correction informations du Conjoint",
    btn_create_conjoint: "Ajouter informations du Conjoint",

    modal_edit_demandeur: "Correction des informations personnelles",
    modal_edit_conjoint: "Correction des informations du Conjoint",
    modal_create_conjoint: "Ajout des informations du Conjoint",
    modal_edit_demande: "Correction des informations de la demande",
    modal_btn_save: "Enregistrer",
    popup_title_success: "L'opération de modification a réussi",
    popup_message_success_demandeur: "Vos informations personnelles ont été modifiées avec succès",
    popup_message_success_conjoint: "Les informations de votre conjoint ont été modifiées avec succès",
    popup_message_success_demande: "Les informations de votre demande ont été modifiées avec succès",
    popup_title_error: "L'opération de modification a échoué",
    popup_message_error_demandeur: "Vos informations personnelles n’ont pas été modifiées",
    popup_message_error_conjoint: "Les informations de votre conjoint n’ont pas été modifiées",
    popup_message_error_demande: "Les informations de votre demande n’ont pas été modifiées",
    popup_title_add_conjoint_success: "Ajout du conjoint avec succès",
    popup_message_add_conjoint_success: "Les informations de votre conjoint ont été ajoutées avec succès, veuillez compléter et corriger les informations personnelles suivantes : Nombre d’enfants et Nombre d’enfants moins 30 ans  ",
    popup_title_add_conjoint_error: "Ajout du conjoint a échoué",
    popup_message_add_conjoint_error: "Les informations de votre conjoint n’ont pas été ajoutées",
    confirmation_delete_conjoint_modal_content:"Vous avez modifié votre situation familiale. Par conséquent, les informations du conjoint seront supprimées. Êtes-vous sûr des informations saisies?",
    confirmation_delete_conjoint_modal_title:"Supression des informations du conjoint",
    btn_confirmer: "Confirmer",
    // ConjoinAlreadytExist:"",
    // Demandeur_Not_Exist:"",
    // Conjoint_Not_Exist:"",
    // Demande_Not_Exist:"",
    Conjoint_Not_Defined:"Veuillez ajouter d'abord les informations de votre conjoint, puis de renseigner les informations personnelles en relation."
};
export type AppTranslationValues = typeof values;
const lang = { translation: values };
export default lang;
