import { Radio, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import { RegisterStepProps } from ".";
import { FocusEvent, useEffect, useState } from "react";
import dayjs from "dayjs";
import { situation_professionnelle_conjoint } from "../../data/data";
import {
  arabicInputNormalize,
  getCleASS,
  latinInputNormalize,
  nActNormalize,
  ninNormalize,
  nssNormalize,
} from "../../helpers/validators";
import { getCommunes } from "../../api/wilayas";
import { ICommune } from "../../types/types";
import CustomInputNumber from "../../components/CustomInputNumber";

const ConjointStep = ({ form, current, wilayas }: RegisterStepProps) => {
  const { t, i18n } = useTranslation();

  const [communes, setCommunes] = useState<ICommune[]>([]);
  const [loadingCommunes] = useState(false);
  const [conjointSitutationProfessionnel, setConjointSitutationProfessionnel] = useState(0);

  const onWilayaChange = (value: number) => {
    form.setFieldsValue({
      cojoint: {
        communeNaissanceId: undefined,
      },
    });
    return getCommunes(value).then((res) => setCommunes(res));
  };

  const onBlurDateNaissance = (e: FocusEvent<HTMLInputElement, Element>) => {
    let date = e.target.value;
    if (date) {
      let date1 = dayjs(date, "YYYY-MM-DD");
      let date2 = dayjs(date, "DD-MM-YYYY");
      let date3 = dayjs(date, "DD/MM/YYYY");
      let date4 = dayjs(date, "YYYY/MM/DD");
      let res = date1.isValid()
        ? date1
        : date2.isValid()
        ? date2
        : date3.isValid()
        ? date3
        : date4.isValid()
        ? date4
        : null;
      if (res) {
        form.setFieldsValue({
          cojoint: {
            dateNaissance: res,
          },
        });
      }
    }
    form.validateFields([["cojoint", "dateNaissance"]]);
    form.validateFields([["cojoint", "numSecuriteSociale"]]);
  };

  const nssPattern = () => {
    let nss = form.getFieldValue(["cojoint", "numSecuriteSociale"]) || "";
    let dateNaissance = form.getFieldValue(["cojoint", "dateNaissance"]) || "";
    if (`${nss}`.length === 12 && dateNaissance) {
      let year = dateNaissance.format("YY");
      let key = getCleASS(nss);
      let nss2 = `${nss}`.slice(2, 10);
      let pattern = `^${year}${nss2}${key}$`;
      return new RegExp(pattern);
    }
    return new RegExp(`^[0-9]{12}$`);
  };
  const show = current === 2;

  const formatterNumber = (val: any) => {
    if (!val) return "0";
    return `${val}`.replace(",", "");
  };

  const parserNumber = (val: any) => {
    if (!val) return "0";
    return Number.parseFloat(val.replace(",", ".")).toFixed(2);
  };
  return (
    <div
      style={
        !show
          ? {
              display: "none",
            }
          : {}
      }
    >
      <Form.Item
        name={["cojoint", "nomAr"]}
        label={t("inscription.nom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "nomLt"]}
        rules={[{ required: true }]}
        label={t("inscription.nom_latin")}
        normalize={latinInputNormalize}
      >
        <Input onInput={(e:React.ChangeEvent<HTMLInputElement>) => e.target.value = e.target.value.toUpperCase()} />
      </Form.Item>

      <Form.Item
        name={["cojoint", "prenomAr"]}
        label={t("inscription.prenom_arabe")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "prenomLt"]}
        label={t("inscription.prenom_latin")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input onInput={(e:React.ChangeEvent<HTMLInputElement>) => e.target.value = e.target.value.toUpperCase()} />
      </Form.Item>
      <div style={{ border: "2px solid #ddd", padding: 10, marginBottom: 20 }}>
        <p
          style={{
            width: i18n.language === "ar" ? "90px" : "140px",
            marginTop: "-24px",
            marginLeft: "5px",
            background: "white",
          }}
        >
          {t("inscription.date_naissance")}
        </p>
        <Form.Item
          name={["cojoint", "presumeConjoint"]}
          label={t("inscription.presume")}
          initialValue={false}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value={false}>{t("inscription.non")}</Radio>
            <Radio value={true}>{t("inscription.oui")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={["cojoint", "dateNaissance"]}
          label={t("inscription.date_naissance")}
          rules={[
            { required: true },
            {
              validator(_, value) {
                const presume = form.getFieldValue([
                  "cojoint",
                  "presumeConjoint",
                ]);
                if (!value) {
                  return Promise.resolve();
                }
                if (presume) {
                  if (value.month() !== 0 || value.date() !== 1) {
                    return Promise.reject(
                      new Error(t("messages.presume_invalide").toString())
                    );
                  }
                }
                if (value.isAfter(dayjs().subtract(16, "year"))) {
                  console.log(value);
                  return Promise.reject(
                    new Error(t("messages.date_invalide").toString())
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            onBlur={onBlurDateNaissance}
            style={{ width: "100%" }}
            defaultPickerValue={dayjs().subtract(16, "year")}
            placeholder=""
            disabledDate={(date) => date.isAfter(dayjs().subtract(16, "year"))}
          />
        </Form.Item>
      </div>

      <Form.Item
        name={["cojoint", "wilayaNaissanceId"]}
        label={t("inscription.wilaya_naissance")}
        rules={[{ required: true }]}
      >
        <Select onChange={onWilayaChange}>
          {wilayas?.map((wilaya) => (
            <Select.Option key={wilaya.id} value={wilaya.id}>
              {wilaya.id}-{" "}
              {i18n.language == "ar" ? wilaya.nomWilayaAr : wilaya.nomWilayaLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["cojoint", "communeNaissanceId"]}
        label={t("inscription.commune_naissance")}
        dependencies={[["cojoint", "wilayaNaissanceId"]]}
        rules={[{ required: true }]}
      >
        <Select loading={loadingCommunes}>
          {communes.map((commune) => (
            <Select.Option key={commune.id} value={commune.id}>
              {i18n.language == "ar"
                ? commune.nomCommuneAr
                : commune.nomCommuneLt}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={["cojoint", "numActeNaissance"]}
        label={t("inscription.n_acte_naissance")}
        normalize={nActNormalize}
        rules={
          [
            // { required: true }
          ]
        }
      >
        <CustomInputNumber />
      </Form.Item>

      <Form.Item
        name={["cojoint", "numIdentificationNational"]}
        label={t("inscription.nin")}
        normalize={ninNormalize}
        rules={[
          // { required: true },
          { len: 18, message: t("messages.nin_invalide").toString() },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["cojoint", "prenomPereAr"]}
        label={t("inscription.prenom_pere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "prenomPereLt"]}
        label={t("inscription.prenom_pere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input onInput={(e:React.ChangeEvent<HTMLInputElement>) => e.target.value = e.target.value.toUpperCase()} />
      </Form.Item>

      <Form.Item
        name={["cojoint", "nomMereAr"]}
        label={t("inscription.nom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "nomMereLt"]}
        label={t("inscription.nom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input onInput={(e:React.ChangeEvent<HTMLInputElement>) => e.target.value = e.target.value.toUpperCase()} />
      </Form.Item>

      <Form.Item
        name={["cojoint", "prenomMereAr"]}
        label={t("inscription.prenom_mere_ar")}
        normalize={arabicInputNormalize}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["cojoint", "prenomMereLt"]}
        label={t("inscription.prenom_mere_lt")}
        normalize={latinInputNormalize}
        rules={[{ required: true }]}
      >
        <Input onInput={(e:React.ChangeEvent<HTMLInputElement>) => e.target.value = e.target.value.toUpperCase()} />
      </Form.Item>
      <Form.Item
        name={["cojoint", "situationProfessionnelle"]}
        label={t("inscription.situation_professionnelle")}
        rules={[{ required: true }]}
      >
        <Select
          onChange={(ev) => {
            setConjointSitutationProfessionnel(ev);

          }}
        >
          {situation_professionnelle_conjoint.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {t(item.label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return (
            prevValues.cojoint?.situationProfessionnelle !==
            currentValues.cojoint?.situationProfessionelle
          );
        }}
      >
        {/*   {({ getFieldValue }) =>
          getFieldValue(["cojoint", "situationProfessionnelle"]) === 1 ? (
            <>
              <Form.Item
                name={["cojoint", "revenuMensuel"]}
                label={t("inscription.revenu_mensuel")}
                rules={[
                  { required: true },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      let floatVal = parseFloat(value);
                      if (floatVal >= 0 && floatVal <= 999999) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t("messages.revenu_mensuel_invalide").toString()
                        )
                      );
                    },
                  },
                ]}
              >
                <InputNumber<string>
                  style={{ width: "40%" }}
                  min="0.00"
                  stringMode
                  keyboard={false}
                  controls={false}
                  step="0.01"
                  addonAfter={t("inscription.dinars")}
                />
              </Form.Item>

              <Form.Item
                name={["cojoint", "numSecuriteSociale"]}
                label={t("inscription.nss")}
                normalize={nssNormalize}
                dependencies={[["cojoint", "dateNaissance"]]}
                rules={[
                  { required: true },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      let pattern = nssPattern();
                      let correct = pattern.test(value);
                      if (correct) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t("messages.nss_invalide").toString())
                      );
                    },
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </>
          ) : null
        } */}
        {conjointSitutationProfessionnel ===1 ? (
          <>
            <Form.Item
              name={["cojoint", "revenuMensuel"]}
              label={t("inscription.revenu_mensuel")}
              rules={[
                { required: true },
                {
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    let floatVal = parseFloat(value);
                    if (floatVal >= 0 && floatVal <= 999999) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t("messages.revenu_mensuel_invalide").toString()
                      )
                    );
                  },
                },
              ]}
            >
              <InputNumber<string>
                style={{ width: "40%" }}
                min="0.00"
                stringMode
                keyboard={false}
                controls={false}
                step="0.01"
                formatter={formatterNumber}
                parser={parserNumber}
                addonAfter={t("inscription.dinars")}
              />
            </Form.Item>

            <Form.Item
              name={["cojoint", "numSecuriteSociale"]}
              label={t("inscription.nss")}
              normalize={nssNormalize}
              dependencies={[["cojoint", "dateNaissance"]]}
              rules={[
                { required: true },
                {
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    let pattern = nssPattern();
                    let correct = pattern.test(value);
                    if (correct) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("messages.nss_invalide").toString())
                    );
                  },
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : null}
         {conjointSitutationProfessionnel===3 ? (
           <Form.Item
              name={["cojoint", "revenuMensuel"]}
              label={t("inscription.revenu_mensuel")}
              rules={[
                { required: true },
                {
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    let floatVal = parseFloat(value);
                    if (floatVal >= 0 && floatVal <= 999999) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t("messages.revenu_mensuel_invalide").toString()
                      )
                    );
                  },
                },
              ]}
            >
              <InputNumber<string>
                style={{ width: "40%" }}
                min="0.00"
                stringMode
                keyboard={false}
                controls={false}
                step="0.01"
                formatter={formatterNumber}
                parser={parserNumber}
                addonAfter={t("inscription.dinars")}
              />
            </Form.Item>
        ):null}
      </Form.Item>
    </div>
  );
};

export default ConjointStep;
